import React from "react";
import type { AppProps } from "next/app";
import Head from "next/head";
import '../styles/dialect.css';
import '../styles/quill.css'

export default function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Soltype</title>
      </Head>
      <div id="root" className="bg-white">
        <Component {...pageProps} />
      </div>
    </>
  );
}
